<script lang="ts">
  export let label = '';
  export let name = '';
  export let value: string | number = '';
  export let placeholder = '';
  export let styleClass = '';
  export let error = '';
  export let type = 'text';
  export let rows = 3; // Default number of rows for textarea
  export let options = [];
  export let min = undefined;
  export let max = undefined;
  export let testId = '';
</script>

<div class={styleClass}>
  {#if label}
    <div class="mb-1">
      <label class="text-sm w-full font-medium text-gray-900" for={name}>
        {label}
      </label>
    </div>
  {/if}
  {#if type === 'number'}
    <input
      data-cy={testId}
      id={name}
      min={min}
      max={max}
      on:change
      class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
      ${error ? 'ring-red-500' : ''}
    `}
      {...{ type }}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    />
  {/if}
  {#if error}
    <span class="text-xs text-red-600 font-medium w-full">{error}</span>
  {/if}
  {#if type === 'select'}
    <select
      data-cy={testId}
      id={name}
      on:change
      class={`w-full focus:outline-none hover:cursor-pointer rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
        ${error ? 'ring-red-500' : ''}
      `}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    >
      {#each options as option}
        <option value={option.value}>{option.text}</option>
      {/each}
    </select>
  {/if}
  {#if type === 'textarea'}
    <textarea
      data-cy={testId}
      id={name}
      rows={rows}
      on:change
      class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
        ${error ? 'ring-red-500' : ''}
      `}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    />
  {/if}
  {#if type !== 'select' && type !== 'textarea' && type !== 'number'}
    <input
      data-cy={testId}
      id={name}
      min={min}
      max={max}
      on:change
      class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
        ${error ? 'ring-red-500' : ''}
      `}
      {...{ type }}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    />
  {/if}
  {#if error}
    <span class="text-xs text-red-600 font-medium w-full">{error}</span>
  {/if}
</div>
