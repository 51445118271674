import type {
  ExperienceLanguages,
  ICategory,
  IExperience,
} from '../../../interfaces/IExperience';
import type { IPaginatedQuery } from '../../../interfaces/IPaginatedQuery';
import type { ISession } from '../../../interfaces/ISession';
import type { ISessionUser } from '../../../interfaces/ISessionUser';
import type { IUser } from '../../../interfaces/IUser';
import { get, post, put } from '../api-axios';

export const getUsers = async (query?: IPaginatedQuery) => {
  return await get<{ users: IUser[]; count: number }>('/user', {
    params: query,
  });
};

export const updateUser = async (
  userId: number,
  updatedUser: Partial<IUser>,
) => {
  return await put(`/user/${userId}`, updatedUser);
};

export interface IExperienceQuery extends IPaginatedQuery {
  categoryId?: number;
  language?: ExperienceLanguages;
}
export const getExperiences = async (query?: IExperienceQuery) => {
  return await get<{ experiences: IExperience[]; count: number }>(
    '/experiences',
    {
      params: query,
    },
  );
};

export const createExperience = async (
  newExperience: Omit<
    IExperience,
    | 'id'
    | 'totalRatings'
    | 'totalRaters'
    | 'rating'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
  >,
) => {
  return await post<IExperience>('/experiences', newExperience);
};

export const updateExperience = async (
  updatedExperience: Omit<
    IExperience,
    | 'totalRatings'
    | 'totalRaters'
    | 'rating'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
  >,
) => {
  return await put(`/experiences/${updatedExperience.id}`, updatedExperience);
};

export const createSession = async (
  experienceId: number,
  hostId: number,
  userIds: number[],
) => {
  return await post<ISession>(`/sessions`, {
    experienceId,
    hostId,
    userIds,
  });
};

export interface IExperienceFeedbackQuery extends IPaginatedQuery {
  minRating?: number;
  maxRating?: number;
  rating?: number;
  from?: string;
  to?: string;
}
export const getExperienceFeedback = async (
  experienceId: number,
  query?: IExperienceFeedbackQuery,
) => {
  return await get<{ count: number; sessionUsers: ISessionUser[] }>(
    `/experiences/${experienceId}/feedback`,
    {
      params: {
        ...query,
        from: query.from ? new Date(query.from).toISOString() : undefined,
        to: query.to ? new Date(query.to).toISOString() : undefined,
      },
    },
  );
};

export const getSessions = async (query?: IPaginatedQuery) => {
  return await get<{ sessions: ISession[]; count: number }>('/sessions', {
    params: query,
  });
};

export const updateSession = async (
  sessionId: number,
  updatedSession: Partial<ISession>,
) => {
  return await put(`/sessions/${sessionId}`, updatedSession);
};

export const submitFeedback = async (
  sessionId: number,
  feedback: { rating: number; comment: string },
) => {
  return await put(`/sessions/${sessionId}/feedback`, feedback);
};

export const uploadImage = async (image: File) => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('folder', 'experience-image');
  return await post<string>('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder', 'documents');
  return await post<string>('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createCategory = async (
  category: Omit<ICategory, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'>,
) => {
  return await post('/categories', category);
};

export const updateCategory = async (
  category: Omit<ICategory, 'createdAt' | 'updatedAt' | 'deletedAt'>,
) => {
  return await put(`/categories/${category.id}`, category);
};

export const getCategory = async (id: number) => {
  return await get<ICategory>(`/categories/${id}`);
};

export const getAllCategories = async () => {
  return await get<{ categories: ICategory[]; count: number }>('/categories');
};
