<script lang="ts">
  import moment from 'moment';

  import StarIcon from '../../../assets/svg/StarIcon.svelte';
  import UserIcon from '../../../assets/svg/UserIcon.svelte';
  import type { ISessionUser } from '../../../interfaces/ISessionUser';

  export let sessionUser: ISessionUser;
</script>

<div
  data-cy="feedback-card"
  class="grid h-36 w-72 shrink-0 grid-cols-5 overflow-hidden rounded-lg border border-light-grey p-4"
>
  <div class="h-full w-fit grid-cols-1 items-center">
    <UserIcon style="fill-grey w-9 h-9" />
  </div>
  <div class="col-span-4 flex w-full flex-col">
    <div class="flex flex-row justify-between">
      <span class="text-sm font-bold text-dark-grey"
        >{sessionUser.user.name} {sessionUser.user.lastName}</span
      >
      <div class="flex flex-col items-end">
        <span class="text-xs text-grey"
          >{moment(sessionUser.updatedAt).fromNow()}</span
        >
        <div class="flex flex-row items-center gap-1">
          <span class="text-xs text-grey">{sessionUser.feedbackRating}</span
          ><StarIcon style="fill-primary w-2.5 h-2.5" />
        </div>
      </div>
    </div>
    <div class="w-full h-20 overflow-auto">
      <p class="text-xs text-grey">
        {sessionUser.feedbackComment}
      </p>
    </div>
  </div>
</div>
