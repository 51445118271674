<script lang="ts">
  import { link, useNavigate } from 'svelte-navigator';

  import togetherLogo from '../../../assets/Together-01.svg';
  import CardText from '../../../assets/svg/CardText.svelte';
  import ChartPie from '../../../assets/svg/ChartPie.svelte';
  import CircleXMark from '../../../assets/svg/CircleXMark.svelte';
  import Dice from '../../../assets/svg/Dice.svelte';
  import Gamepad from '../../../assets/svg/Gamepad.svelte';
  import MenuBars from '../../../assets/svg/MenuBars.svelte';
  import StackedBoxes from '../../../assets/svg/StackedBoxes.svelte';
  import StarIcon from '../../../assets/svg/StarIcon.svelte';
  import TagIcon from '../../../assets/svg/TagIcon.svelte';
  import { logout } from '../../services/together/user.api';
  import clickOutside from '../helpers/clickOutside';

  let isSidebarOpen = true;
  let screenSize: number;
  let screenMdSize = 768;

  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (screenSize >= screenMdSize) {
      isSidebarOpen = true;
    } else {
      isSidebarOpen = !isSidebarOpen;
    }
  };

  const handleLogout = async (): Promise<void> => {
    await logout();
    navigate('/');
  };

  $: screenSize >= screenMdSize
    ? (isSidebarOpen = true)
    : (isSidebarOpen = false);
</script>

<svelte:window bind:innerWidth={screenSize} />
<header class="relative md:hidden bg-white border-b border-gray-200 z-10">
  <div
    class="relative max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2 px-2"
  >
    <div class="md:basis-0 flex flex-wrap justify-center">
      <h1 class="text-3xl hover:scale-105 transition ease-in duration-200">
        <button on:click|stopPropagation={toggleSidebar}>
          <MenuBars size="h-6 w-6" />
        </button>
      </h1>
    </div>
    <div>
      <button
        class="flex items-center rounded-md w-full px-6 py-2 my-1 font-semibold hover:bg-primary/70 hover:text-gray-900 transition ease-in duration-200"
        on:click={handleLogout}>Logout</button
      >
    </div>
  </div>
</header>

{#if isSidebarOpen}
  <div
    class="md:flex flex-col fixed inset-0 z-20 bg-gray-200 inset-y-0 left-0 w-64 shadow-xl overflow-y-auto transition duration-300 transform lg:translate-x-0"
    use:clickOutside={toggleSidebar}
  >
    <div>
      <div class="relative flex items-center justify-center">
        <div class="flex flex-col gap-4">
          <img
            class="w-full h-full p-6"
            src={togetherLogo}
            alt="Together logo"
          />
        </div>
        <button
          on:click={toggleSidebar}
          class="flex md:hidden hover:scale-110 transition ease-in duration-200 p-1"
        >
          <CircleXMark
            color="text-primary hover:text-black transition ease-in duration-200"
          />
        </button>
      </div>
      <nav class="mt-6 mx-4 text-lg">
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin"
          use:link
        >
          <ChartPie />
          <span>Users</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/boxes"
          use:link
        >
          <StackedBoxes />
          <span>Boxes</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/games"
          use:link
        >
          <Gamepad />
          <span>Games</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/experiences"
          use:link
        >
          <Gamepad />
          <span>Experiences</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/feedback"
          use:link
        >
          <StarIcon />
          <span>Feedback</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/categories"
          use:link
        >
          <TagIcon styleClass="h-5 w-5 relative top-0.5" />
          <span>Categories</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/mini-games"
          use:link
        >
          <Dice />
          <span>Mini Games</span>
        </a>
        <a
          class="flex items-center gap-2 font-semibold px-6 py-2 my-1 text-gray-600 rounded-md hover:bg-gray-400/25 hover:text-gray-900 transition ease-in duration-200"
          href="/admin/submissions"
          use:link
        >
          <CardText />
          <span>Submissions</span>
        </a>
        <button
          class="flex items-center rounded-md w-full px-6 py-2 my-1 font-semibold text-gray-600 hover:bg-primary/70 hover:text-gray-900 transition ease-in duration-200"
          on:click={handleLogout}>Logout</button
        >
      </nav>
    </div>
  </div>
{/if}
